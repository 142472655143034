import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  QuickQuoteGroup,
  LeadformGroup,
  ButtonLink,
} from '@simply-fin-services/astronomix3'
import BaseBlockContent from '@sanity/block-content-to-react'
import { GROUP_URL_BASE, POSTAL_URL_BASE, CAMPAIGN } from '../../../env'

import Tick from './tick-blue.svg'

const ButtonContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  button:first-of-type {
    margin-right: 1rem;
  }
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  main {
    ul {
      list-style-position: outside;
      padding-left: 1rem;
      li {
        padding-left: 0.5rem;
      }
    }
  }
  span.tick + br {
    display: none;
  }
  p + .block-image {
    margin-bottom: 2rem;
  }
  ul + p:empty {
    display: none;
  }
  p + p:empty {
    display: none;
  }
  p + button {
    margin-top: 1rem;
    margin-bottom: 0;
    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  button {
    margin-bottom: 0.5rem;
  }
  ${(props) =>
    props.textcolour &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      li {
        color: ${(props) => props.textcolour};
      }
    `}
  align-items: ${(props) => (props.alignment ? props.alignment : 'flex-start')};
  justify-content: ${(props) =>
    props.justification ? props.justification : 'flex-start'};
  flex-direction: column;
  ul {
    margin-bottom: 0;
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
`

const PhotoContainer = styled.div`
  img {
    max-width: 200px;
  }
`

const ComponentContainer = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 0rem;
  }
`

const TickContainer = styled.span`
  display: block;
  display: flex;
  align-items: center;
  img {
    margin-top: 0;
    margin-right: 1rem;
    margin-bottom: 0 !important;
    vertical-align: middle;
    box-shadow: none;
  }
`

const highlight = (props) => {
  return <mark>{props.children}</mark>
}

const check = (props) => {
  if (props.children[0] !== '') {
    return (
      <TickContainer className="tick">
        <img src={Tick} alt="" /> <span>{props.children}</span>
      </TickContainer>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}

const serializers = {
  marks: {
    highlight,
    check,
  },
  types: {
    img(props) {
      props.node.asset ? (
        <div>
          <img src={`${props.node.asset.src}?auto=format&q=90&w=600`} alt="" />
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )
    },
    component(props) {
      if (props.node.component === 'grouppricing') {
        return <ComponentContainer>Group Pricing</ComponentContainer>
      } else if (props.node.component === 'qqgroup') {
        return (
          <ComponentContainer>
            <QuickQuoteGroup
              company={props.node.company}
              department={props.node.department}
              department_label={props.node.department_label}
              discount={props.node.discount}
              person_label={props.node.person_label}
              person={props.node.person}
              group_url={GROUP_URL_BASE}
              isWhite={props.node.iswhite}
              textcolour={props.node.textcolour}
              withShadow={props.node.withshadow}
            />
          </ComponentContainer>
        )
      } else if (props.node.component === 'leadformgroup') {
        return (
          <ComponentContainer>
            <LeadformGroup
              campaign={CAMPAIGN}
              person={props.node.person}
              person_label={props.node.person_label}
              company={props.node.company}
              department={props.node.department}
              department_label={props.node.department_label}
              discount={props.node.discount}
              endpoint={`${POSTAL_URL_BASE}/postal-api/hotlead`}
              isWhite={props.node.iswhite}
              textcolour={props.node.textcolour}
              withShadow={props.node.withshadow}
            />
          </ComponentContainer>
        )
      } else {
        return <div></div>
      }
    },
    button(props) {
      if (props.node && props.node.button_text) {
        const colour = props.node.button_type || 'primary'
        return (
          <ButtonLink colour={colour} url={props.node.button_url}>
            {props.node.button_text}
          </ButtonLink>
        )
      } else {
        return <div></div>
      }
    },
    buttons(props) {
      if (props.node && props.node.button_1_title) {
        return (
          <ButtonContainer>
            <ButtonLink primary url={props.node.button_1_url}>
              {props.node.button_1_title}
            </ButtonLink>
            {props.node.button_2_url && (
              <ButtonLink secondary url={props.node.button_2_url}>
                {props.node.button_2_title}
              </ButtonLink>
            )}
          </ButtonContainer>
        )
      } else {
        return <div></div>
      }
    },
    photo(props) {
      if (props.node && props.node.asset.metadata) {
        return (
          <PhotoContainer>
            <img
              src={`${props.node.asset.url}?auto=format&q=90&w=600`}
              alt=""
            />
          </PhotoContainer>
        )
      } else {
        return <React.Fragment></React.Fragment>
      }
    },
    block(props) {
      // eslint-disable-next-line react/prop-types
      const { node, children } = props
      // eslint-disable-next-line react/prop-types
      switch (node.style) {
        case 'h1':
          return <h1>{children}</h1>

        case 'h2':
          return <h2>{children}</h2>

        case 'h3':
          return <h3>{children}</h3>

        case 'h4':
          return <h4>{children}</h4>

        case 'h5':
          return <h5>{children}</h5>

        case 'h6':
          return <h6>{children}</h6>

        default:
          return <p>{children}</p>
      }
    },
  },
}

const BlockContent = (props) => {
  const { blocks, alignment, justification, textcolour } = props

  if (blocks) {
    const optimizedBlocks = blocks.map((block) => {
      if (block._type === 'photo') {
        let photoBlock = block
        console.log('aaa', photoBlock.asset.url)
        photoBlock['url'] = `${photoBlock.asset.url}?auto=format&q=90`
        return photoBlock
      }
      return block
    })
    return (
      <Wrapper
        className="block-wrapper"
        alignment={alignment}
        justification={justification}
        textcolour={textcolour}
      >
        {optimizedBlocks.map((b, index) => {
          return (
            <BaseBlockContent
              key={index}
              className="block"
              blocks={b}
              serializers={serializers}
            />
          )
        })}
      </Wrapper>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}

BlockContent.propTypes = {
  blocks: PropTypes.object,
  alignment: PropTypes.object,
  justification: PropTypes.object,
  textcolour: PropTypes.object,
}

export default BlockContent

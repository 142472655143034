// (* + *)
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Owl = styled.div`
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  & > * + * {
    margin-top: ${(props) => props.size};
    @media (max-width: 768px) {
      margin-top: 0;
      margin-bottom: ${(props) => props.sizemobile};
    }
  }
`

Owl.defaultProps = {
  direction: 'top',
  size: '6rem',
  sizemobile: '3rem',
}

Owl.propTypes = {
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  size: PropTypes.string,
  sizemobile: PropTypes.string,
}

export default Owl

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  position: relative;
  @media (max-width: 1090px) {
    flex-direction: column;
    max-width: 100%;
  }
  div {
    display: flex;
    flex: 1 0;
    margin: 0px 16px 0px 16px;
  }
`

const Table = styled.table`
  padding: 16px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0px;
  min-height: 400px;
  caption {
    padding: 8px 8px 8px 12px;
    font-size: 24px;
    padding-bottom: 8px;
    text-align: left;
    font-weight: 500;
    @media (max-width: 1090px) {
      margin-top: 16px;
    }
  }
  thead {
    font-weight: bold;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
  }
  tbody {
    border-left: 1px solid #eee;
  }
  th,
  td {
    padding: 12px 24px 12px 12px;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
  }
`

const GroupPricing = () => {
  return (
    <Container>
      <div>
        <Table>
          <caption>Product types</caption>
          <thead>
            <tr>
              <th>Product</th>
              <th>Participation Rate</th>
              <th>Guaranteed Cover</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Compulsory</td>
              <td>100%</td>
              <td>Yes, for groups of 5+</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div>
        <Table>
          <caption>Cover limits</caption>
          <thead>
            <tr>
              <th>Employee</th>
              <th>Guaranteed Cover per Employee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Up to 49%</td>
              <td>
                Up to 500k life cover
                <br />
                Up to R500k disability
                <br />
                Up to R50k family funeral cover
              </td>
            </tr>
            <tr>
              <td>50-99</td>
              <td>
                Up to 1m life
                <br />
                Up to R1m disability
                <br />
                Up to R50k family funeral cover
              </td>
            </tr>
            <tr>
              <td>100+</td>
              <td>
                Up to 1.5m life
                <br />
                Up to R1.5m disability
                <br />
                Up to R50k family funeral cover
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Container>
  )
}

export default GroupPricing

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Owl from '../Owl'
import { GridThemeProvider } from 'styled-bootstrap-grid'
import {
  Theme,
  Intro,
  Heading,
  LayoutCentered,
  Banner1,
  Banner2,
  Banner3,
  Layout1,
  Layout2,
  Layout3,
  Layout4,
  Layout5,
  Layout6,
  Layout7,
  Layout8,
  LayoutHalfHalf,
  ButtonLink,
} from '@simply-fin-services/astronomix3'
import SanityRowHero from '../SanityRowHero'
import BlockContent from '../BlockContent'

const IMAGE_QUALITY = 75
const PADDING = '6rem'
const PADDINGMOBILE = '2rem'

const GRIDTHEME = {
  gridColumns: 12, // default 12
  breakpoints: {
    // defaults below
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0, // default 15
  },
  col: {
    padding: 16, // default 15
  },
  container: {
    padding: 0, // default 15
    maxWidth: {
      // defaults below
      xxl: 1140,
      xl: 1140,
      lg: 1140,
      md: 1140,
      sm: 1140,
      xs: 1140,
    },
  },
}

const HeroContainer = styled.div`
  h3 {
    color: #fff !important;
  }
`

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: ${(props) => (props.pt ? PADDING : '0')};
  padding-bottom: ${(props) => (props.pb ? PADDING : '0')};
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  &.heading {
    h1,
    h2 {
      margin-bottom: 0;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 768px) {
    padding-top: ${(props) => (props.pt ? PADDINGMOBILE : '0')};
    padding-bottom: ${(props) => (props.pb ? PADDINGMOBILE : '0')};
    h1,
    h2 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  @media (max-width: 500px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
  padding-bottom: 5rem;
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  button {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  @media (max-width: 768px) {
    padding-right: 0.5rem;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
`

const optimizeImageUrl = (url) => `${url}?auto=format&q=${IMAGE_QUALITY}`

const Page = (props) => {
  const { rows } = props

  return (
    <Theme themeName="rml" alwaysLight noMargin>
      <GridThemeProvider gridTheme={GRIDTHEME}>
        <Owl size={PADDING} sizemobile={PADDINGMOBILE}>
          {rows.map((row, index) => {
            const foregroundcolour = row.foregroundcolour
              ? row.foregroundcolour
              : 'inherited'
            const backgroundcolour =
              row.backgroundcolour && row.backgroundcolour.bgcolour
                ? row.backgroundcolour.bgcolour
                : 'transparent'
            const backgroundsize = row.bg_size ? row.bg_size : 'auto'
            const backgroundposition = row.bg_position
              ? row.bg_position
              : '0% 0%'
            const backgroundimage =
              row.backgroundimage && row.backgroundimage.asset.url
                ? row.backgroundimage.asset.url
                : false
            const minheight = row.min_height ? row.min_height + 'vh' : '0'
            const textalign = row.centertext ? 'center' : 'left'
            const icon = row.icon && row.icon.url ? row.icon.url : null
            const paddingtop = row.pt ? true : false
            const paddingbottom = row.pb ? true : false

            const icon1 =
              row.col1icon && row.col1icon.asset.url
                ? row.col1icon.asset.url
                : null
            const icon2 =
              row.col2icon && row.col2icon.asset.url
                ? row.col2icon.asset.url
                : null
            const icon3 =
              row.col3icon && row.col3icon.asset.url
                ? row.col3icon.asset.url
                : null

            const contrast = false

            switch (row._type) {
              case 'row_hero':
                return (
                  <HeroContainer key={index}>
                    <SanityRowHero
                      className="hero"
                      row={row}
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                    />
                  </HeroContainer>
                )

              case 'cta_buttons':
                return (
                  <ButtonWrapper textalign={textalign} key={index}>
                    <ButtonLink url={row.button_1_url}>
                      {row.button_1_title}
                    </ButtonLink>
                    {row.button_2_title && (
                      <ButtonLink url={row.button_2_url} colour="secondary">
                        {row.button_2_title}
                      </ButtonLink>
                    )}
                  </ButtonWrapper>
                )

              case 'heading':
                return (
                  <Wrapper
                    paddingtop={paddingtop}
                    paddingbottom={paddingbottom}
                    textalign={textalign}
                    key={index}
                    className="heading"
                  >
                    <h2 className="h1">{row.heading}</h2>
                    {row.subheading && <h3>{row.subheading}</h3>}
                  </Wrapper>
                )

              case 'paragraph':
                return (
                  <Wrapper
                    key={index}
                    paddingtop={paddingtop}
                    paddingbottom={paddingbottom}
                  >
                    {row.heading && <Heading>{row.heading}</Heading>}
                    {row.subheading && <h3>{row.subheading}</h3>}
                    <BlockContent
                      blocks={row.col1}
                      imageOptions={{ w: 546, h: 400, fit: 'max' }}
                    />
                  </Wrapper>
                )

              case 'intro':
                return (
                  <div key={index}>
                    <Intro
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      icon={icon}
                      heading={row.heading}
                      contrast={contrast}
                      cta_link={row.cta_link}
                      cta_text={row.cta_text}
                      reverse={row.reverse}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      eyebrow={row.eyebrow}
                    >
                      <BlockContent blocks={row.col1} />
                    </Intro>
                  </div>
                )

              case 'layouthalfhalf':
                return (
                  <div key={index}>
                    <LayoutHalfHalf
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      noshadow={row.hide_dropshadow}
                      contrast={contrast}
                      cta_link={row.cta_link}
                      cta_text={row.cta_text}
                      reverse={row.reverse}
                      as_image={row.as_image}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      eyebrow={row.eyebrow}
                    >
                      <BlockContent blocks={row.col1} />
                    </LayoutHalfHalf>
                  </div>
                )

              case 'layout_centered':
                return (
                  <div key={index}>
                    <LayoutCentered
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      subheading={row.subheading}
                      contrast={contrast}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundcolour={backgroundcolour}
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          alignment="center"
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'banner1':
                return (
                  <div key={index}>
                    <Banner1
                      line1={row.line1}
                      line2={row.line2}
                      button_url_1={row.button_url_1}
                      button_url_2={row.button_url_2}
                      button_text_1={row.button_text_1}
                      button_text_2={row.button_text_2}
                    />
                  </div>
                )

              case 'banner2':
                return (
                  <div key={index}>
                    <Banner2
                      line1={row.line1}
                      line2={row.line2}
                      button_url_1={row.button_url_1}
                      button_url_2={row.button_url_2}
                      button_text_1={row.button_text_1}
                      button_text_2={row.button_text_2}
                    />
                  </div>
                )

              case 'banner3':
                return (
                  <div key={index}>
                    <Banner3
                      line1={row.line1}
                      line2={row.line2}
                      button_url_1={row.button_url_1}
                      button_url_2={row.button_url_2}
                      button_text_1={row.button_text_1}
                      button_text_2={row.button_text_2}
                    />
                  </div>
                )

              case 'layout1':
                return (
                  <div key={index}>
                    <Layout1
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      contrast={contrast}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundcolour={backgroundcolour}
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'layout2':
                return (
                  <div key={index}>
                    <Layout2
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      contrast={contrast}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col2={
                        <BlockContent
                          blocks={row.col2}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'layout3':
                return (
                  <div key={index}>
                    <Layout3
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      contrast={contrast}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col1icon={icon1}
                      col2={
                        <BlockContent
                          blocks={row.col2}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col2icon={icon2}
                      col3={
                        <BlockContent
                          blocks={row.col3}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col3icon={icon3}
                    />
                  </div>
                )

              case 'layout4':
                return (
                  <div key={index}>
                    <Layout4
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      contrast={true}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col2={
                        <BlockContent
                          blocks={row.col2}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col3={
                        <BlockContent
                          blocks={row.col3}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col4={
                        <BlockContent
                          blocks={row.col4}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'layout5':
                return (
                  <div key={index}>
                    <Layout5
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      contrast={contrast}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col2={
                        <BlockContent
                          blocks={row.col2}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'layout6':
                return (
                  <div key={index}>
                    <Layout6
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      contrast={contrast}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                      col2={
                        <BlockContent
                          blocks={row.col2}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'layout7':
                return (
                  <div key={index}>
                    <Layout7
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      contrast={contrast}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              case 'layout8':
                return (
                  <div key={index}>
                    <Layout8
                      paddingtop={paddingtop}
                      paddingbottom={paddingbottom}
                      heading={row.heading}
                      hide_heading={row.hide_heading}
                      subheading={row.subheading}
                      backgroundimage={
                        backgroundimage
                          ? optimizeImageUrl(backgroundimage)
                          : false
                      }
                      backgroundsize={backgroundsize}
                      backgroundposition={backgroundposition}
                      backgroundcolour={backgroundcolour}
                      foregroundcolour={foregroundcolour}
                      minheight={minheight}
                      contrast={contrast}
                      col1={
                        <BlockContent
                          blocks={row.col1}
                          imageOptions={{ w: 546, h: 400, fit: 'max' }}
                        />
                      }
                    />
                  </div>
                )

              default:
                ;<div key={index}></div>
            }
          })}
        </Owl>
      </GridThemeProvider>
    </Theme>
  )
}

Page.propTypes = {
  rows: PropTypes.array.isRequired,
}

export default Page

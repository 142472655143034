import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  QuickQuoteGroup,
  LeadformGroup,
} from '@simply-fin-services/astronomix3'
import GroupPricing from '../../components/GroupPricing'
import BlockContent from '../BlockContent'

const CAMPAIGN = 'rml-Leadform'
const SENDER_REFERENCE = 'rml'
const POSTALDISTRIX_URL_BASE = process.env.POSTALDISTRIX_URL_BASE

const Flex = styled.div`
  display: flex;
  justify-content: center;
  min-height: ${(props) => props.min_height};
  background-image: url(${(props) => props.background_image});
  background-position: 50% 50%;
  background-size: cover;
  align-items: center;
  max-width: 100vw;
  flex-direction: column;
  h1,
  h2,
  h3 {
    color: ${(props) => props.text_colour};
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
  width: 100%;
  padding-left: 120px;
  padding-right: 120px;
  flex-direction: ${(props) => props.flexdirection};
  @media (max-width: 768px) {
    flex-direction: ${(props) => props.columndirection};
    padding-left: 0px;
    padding-right: 0px;
  }
`

const TextPane = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  @media (max-width: 768px) {
    padding-top: ${(props) => props.pt};
    padding-bottom: ${(props) => props.pb};
    padding-left: 20px;
    padding-right: 20px;
  }
`

const ComponentPane = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 32px 80px 32px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: ${(props) => props.pt};
    padding-bottom: ${(props) => props.pb};
  }
  & > div {
    border-radius: 6px;
  }
  h2 {
    color: #1a202c;
  }
`

const TopTextContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  margin-top: 80px;
  padding-left: 12px;
  padding-right: 12px;
  @media (max-width: 768px) {
    text-align: center;
  }
  h1 {
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
`

const TextContainer = styled.div`
  min-width: 492px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifycontent};
  h1 {
    max-width: 550px;
    margin-bottom: 0.5rem;
    text-shadow: 2px 2px 2px #00000066;
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
  h3 {
    max-width: 550px;
    margin-bottom: 0px;
    text-shadow: 2px 2px 2px #00000066;
  }
  p {
    font-size: 1.5rem;
    line-height: 1.8rem;
    max-width: 550px;
    font-weight: 500;
    text-shadow: 2px 2px 2px #00000066;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
    max-width: 100vw;
    min-width: 0;
    text-align: center;
  }
`

const ComponentWrapper = styled.div`
  padding: 16px;
  div.segmented {
    button {
      background-color: #00597c;
      border-color: #00597c;
      color: #fff;
      line-height: normal;
      &.selected {
        background-color: #008eb3 !important;
        border-color: #008eb3;
        color: #fff;
      }
    }
  }
  section {
    padding-bottom: 12px;
    .a-field-container {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  @media (max-width: 768px) {
    padding: 8px;
  }
`

const Content = styled.div`
  margin-top: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p {
    color: #fff;
  }
`

const componentinator = (qq) => {
  if (!qq) return <></>

  if (qq.component === 'grouppricing') {
    return (
      <ComponentWrapper>
        <GroupPricing />
      </ComponentWrapper>
    )
  }

  if (qq.component === 'qqgroup') {
    return (
      <ComponentWrapper>
        <QuickQuoteGroup
          phoneNumberRequired
          company={qq.company}
          department={qq.department}
          discount={qq.discount}
          person={qq.person}
          intro={qq.intro}
          withShadow={qq.withshadow}
          sender_reference={SENDER_REFERENCE}
          group_url={process.env.GROUP_URL_BASE}
        />
      </ComponentWrapper>
    )
  }

  if (qq.component === 'leadformgroup') {
    return (
      <ComponentWrapper>
        <LeadformGroup
          campaign={CAMPAIGN}
          phoneNumberRequired
          company={qq.company}
          department={qq.department}
          discount={qq.discount}
          person={qq.person}
          endpoint={`${POSTALDISTRIX_URL_BASE}/postal-api/hotlead`}
        />
      </ComponentWrapper>
    )
  }

  return <div style={{ display: 'none' }}>&nbsp;</div>
}

const SanityRowHero = ({ row }) => {
  const {
    bg_image,
    component,
    description,
    flexdirection,
    min_height,
    textcolour,
    textposition,
    texttop,
    content,
    title,
  } = row

  const background_image = bg_image.asset.url
  const mh = min_height ? min_height + 'vh' : '80vh'
  const row_textcolour =
    textcolour && textcolour.hex ? textcolour.hex : '#ffffff'
  const row_textposition = textposition || 'center'
  const row_flexdirection = flexdirection || 'row'
  const column_flexdirection =
    flexdirection === 'row-reverse' ? 'column' : 'column-reverse'

  let text_padding_top =
    column_flexdirection === 'column-reverse' ? '80px' : '0px'

  if (texttop) {
    text_padding_top = '0px'
  }

  const text_padding_bottom =
    column_flexdirection === 'column-reverse' ? '0px' : '40px'
  const component_padding_top =
    column_flexdirection === 'column-reverse' ? '40px' : '80px'
  const component_padding_bottom =
    column_flexdirection === 'column-reverse' ? '80px' : '40px'

  return (
    <Flex
      min_height={mh}
      background_image={background_image}
      text_colour={row_textcolour}
    >
      {texttop && (
        <TopTextContainer>
          <h1>{title}</h1>
          <h3>{description}</h3>
          {content && (
            <Content>
              <BlockContent blocks={row.content} />
            </Content>
          )}
        </TopTextContainer>
      )}
      <Inner
        flexdirection={row_flexdirection}
        columndirection={column_flexdirection}
      >
        <ComponentPane pt={component_padding_top} pb={component_padding_bottom}>
          {componentinator(component)}
        </ComponentPane>
        <TextPane pt={text_padding_top} pb={text_padding_bottom}>
          <TextContainer justifycontent={row_textposition}>
            {!texttop && (
              <React.Fragment>
                <h1>{title}</h1>
                <h3>{description}</h3>
                {content && (
                  <Content>
                    <BlockContent blocks={row.content} />
                  </Content>
                )}
              </React.Fragment>
            )}
          </TextContainer>
        </TextPane>
      </Inner>
    </Flex>
  )
}

SanityRowHero.propTypes = {
  row: PropTypes.object,
}

export default SanityRowHero

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Page from '../../components/Page'

export const query = graphql`
  query RenderPage($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      metadata {
        meta_description
        meta_keywords
        meta_title
        meta_image {
          _key
          _type
        }
      }
      _rawRow(resolveReferences: { maxDepth: 10 })
      _rawMetadata(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Pages = ({ data }) => {
  const page = data.sanityPage

  if (!page) return null

  return (
    <React.Fragment>
      <Layout>
        <Page className="page" rows={page._rawRow} />
      </Layout>
    </React.Fragment>
  )
}

export default Pages

Pages.propTypes = {
  data: PropTypes.object.isRequired,
}
